import React from "react";
import {PageId} from "./Page";

type Props = {
    page: PageId;
    pages: Array<PageId>;
    onChange: (page: PageId) => void;
}

const Next = ({page, pages, onChange}: Props) => {
    const indexOf = pages.indexOf(page);
    if (indexOf === pages.length - 1) {
        return null;
    } else {
        const next = pages[indexOf + 1];

        return <button className={`btn btn-success fs-5`} onClick={() => onChange(next)}>
            Dalej
        </button>;
    }
}

export default Next;
