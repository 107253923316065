import React from 'react';
import {Answer} from '../../model';

type Props = {
    name: string
    answer: Answer
    checked: boolean
    onChange: () => void
};

const AnswerRadio = ({name, answer, checked, onChange}: Props) => {
    return <li className={`list-group-item ${checked ? "bg-primary bg-opacity-25" : ""}`}>
        <label className="form-check form-check-label">
            <input className="col-auto form-check-input"
                   name={name}
                   type="radio"
                   checked={checked}
                   onChange={() => onChange()}/>
            {answer.title}
        </label>
    </li>;
};

export default AnswerRadio;
