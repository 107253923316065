import React from "react";
import {Question, QuestionSection} from "../model";
import QuestionDiv from "../Question";


type Props = {
    id: string
    section: QuestionSection;
    onChange: (question: Question, points: number, clear: Question[]) => void;
};

type State = {
    selected: Map<Question, number>
}

class QuestionSectionDiv extends React.Component<Props, State> {
    state = {
        selected: new Map(),
    };

    render() {
        return <div>
            {this.description()}
            {this.props.section.questions.map((question, i) => {
                const id = `${this.props.id}-answer-${i}`;
                if (question.when === undefined || this.state.selected.get(question.when.question) === question.when.answer.points) {
                    return <QuestionDiv key={id}
                                        id={id}
                                        question={question}
                                        onChange={points => this.onChange(question, points)}/>
                } else {
                    return null;
                }
            })}
        </div>;
    }

    private description() {
        const description = this.props.section.description;
        if (description !== undefined) {
            return <p>{description}</p>;
        } else {
            return null;
        }
    }

    onChange = (question: Question, points: number) => {
        const updated = new Map(this.state.selected);
        updated.set(question, points);
        this.setState({selected: updated});
        const hidden: Question[] = this.props.section.questions.filter(q => {
            return q.when !== undefined && q.when.question === question && q.when.answer.points !== points;
        });
        this.props.onChange(question, points, hidden);

    }
}

export default QuestionSectionDiv;
