import React from 'react';
import Score from "./Score";

type Props = {
    points: number;
};

type Range = {
    from?: number;
    to?: number;
    description: JSX.Element;
}

const ranges: Range[] = [
    {
        to: 200,
        description: <span>
            Dobra robota, żyjesz prawie nie przekraczając swego udziału w ziemskich zasobach.
            Gdyby każdy żył tak jak Ty, prowadzilibyśmy styl życia określany mianem zrównoważonego. Prawie
            70% ludności Ziemi żyje w ten sposób.
        </span>,
    },
    {
        from: 200,
        to: 400,
        description: <span>
            Twój ślad jest mniejszy niż średnia europejska. Gdyby każdy żył tak jak Ty,
            potrzebowalibyśmy co najmniej dodatkowej planety, by sprostać konsumpcji. Żyje tak 15% ludzi.
        </span>,
    },
    {
        from: 400,
        to: 600,
        description: <span>
            Twój ślad odpowiada średniej europejskiej i przekracza trzykrotnie Twój udział w
            ziemskich zasobach, co oznacza, że gdyby wszyscy żyli w ten sposób potrzebowalibyśmy trzech dodatkowych
            planet. Żyje tak 7% ludzi.
        </span>,
    },
    {
        from: 600,
        to: 800,
        description: <span>
            Twój ślad przekracza średnią europejską. Gdyby cała ludzkość prowadziła taki styl życia,
            potrzebne byłyby jeszcze cztery dodatkowe planety. Około 3% ludzi żyje w ten sposób.
        </span>,
    },
    {
        from: 800,
        description: <span>
            Twój ślad zbliżył się do tego, jaki pozostawia przeciętny Amerykanin czy Duńczyk. By
            sprostać takiej konsumpcji potrzeba jeszcze pięć takich planet, jak Ziemia. Około 5% ludzi żyje w ten
            sposób.
        </span>,
    },
]

type RangeDisplayProps = {
    points: number;
    range: Range;
}

function rangeLabel(range: Range) {
    if (range.from === undefined) {
        return `${range.to} pkt. lub mniej`;
    } else {
        if (range.to === undefined) {
            return `Powyżej ${range.from} pkt.`;
        } else {
            return <span>{range.from} &ndash; {range.to} pkt.</span>;
        }
    }
}

function isInRange(range: Range, points: number) {
    if (range.from === undefined) {
        if (range.to === undefined) {
            return false;
        } else {
            return points < range.to;
        }
    } else {
        if (range.to === undefined) {
            return range.from <= points;
        } else {
            return range.from <= points && points < range.to;
        }
    }
}

const RangeDisplay = ({points, range}: RangeDisplayProps) => {
    const isAnswer = isInRange(range, points);
    const label = rangeLabel(range);
    return <tr className={`${isAnswer ? "table-primary" : ""}`}>
        <td>
            {isAnswer ? <strong>{label}</strong> : label}
        </td>
        <td>
            {range.description}
        </td>
    </tr>;
}

const Summary = ({points}: Props) => {
    const pointsScaled = points * 2;
    return <div>
        <p>
            Wiele przyjemności i udogodnień codziennego życia, takich jak drogi, sklepy i budynki wymaga osobnego
            terenu.&nbsp;
            <strong>Kalkulator pomnożył więc wynik przez dwa.</strong>&nbsp;
            Każdy punkt jest równy 0,01 ha. To oznacza, że 400 pkt. odpowiada 4 ha
            wykorzystania powierzchni planety.
        </p>
        <Score points={pointsScaled}
               format="full"/>
        <p>
            Teraz sprawdź do której kategorii się zakwalifikowałeś(-aś):
        </p>
        <table className="table">
            <thead>
            <tr>
                <th>Punkty</th>
                <th>Opis</th>
            </tr>
            </thead>
            <tbody>
            {ranges.map(range => <RangeDisplay key={`${range.from}-${range.to}`}
                                               range={range}
                                               points={pointsScaled}/>)}
            </tbody>
        </table>
    </div>;
};

export default Summary;
