import React from "react";
import './Steps.scss';

const Steps = () =>
    <>
        <blockquote className="steps-header text-center fw-bolder">
            „Nie szukajmy nadziei, szukajmy działania. Wtedy nadzieja przyjdzie”
            &nbsp;<cite>Greta Thunberg</cite>
        </blockquote>
        <ul className="row row-cols-lg-3 list-unstyled text-center fw-bold fs-5 justify-content-evenly">
            <li className="col">
                <span className="steps-dot"/>
                <span className="steps-line-horizontal"/>
                <span className="steps-line-down"/>
                <span className="steps-learn steps-description">
                    Przedstaw, czym jest ślad ekologiczny
                </span>
            </li>
            <li className="col">
                <span className="steps-dot"/>
                <span className="steps-line-horizontal"/>
                <span className="steps-line-down"/>
                <span className="steps-calculate steps-description">
                    Użyj kalkulatora do obliczenia swojego śladu
                </span>
            </li>
            <li className="col">
                <span className="steps-dot"/>
                <span className="steps-line-down"/>
                <span className="steps-brainstorm steps-description">
                    Porozmawiaj z uczniami, jak go zmniejszyć?
                </span>
            </li>
        </ul>
    </>;

export default Steps;
