import React from 'react';
import {Answer, Question} from './model';
import AnswerInput from "./ui/answer/AnswerInput";
import RangeInput from "./ui/answer/Range";


type Props = {
    id: string
    question: Question;
    onChange: (points: number) => void;
};

type State = {
    count: number
    selected: Map<Answer, number>
}

class QuestionDiv extends React.Component<Props, State> {
    state = {
        count: 1,
        selected: new Map(),
    };

    render() {
        return <div>
            {this.description()}
            <ul className="list-group mb-4">
                {this.props.question.answers.map((answer, i) => {
                    const answerId = `${this.props.id}-answer-${i}`;
                    return <AnswerInput key={answerId}
                                        question={this.props.question}
                                        answerId={answerId}
                                        answer={answer}
                                        questionId={this.props.id}
                                        onChange={this.optionChanged}
                                        checked={this.state.selected}/>;
                })}
            </ul>
            {this.divider()}
        </div>;
    }

    private divider() {
        const divider = this.props.question.divider;
        if (divider !== undefined) {
            return <RangeInput count={this.state.count}
                               id={""}
                               title={divider}
                               min={1}
                               step={1}
                               max={10}
                               onChange={this.dividerChanged}/>;
        } else {
            return null;
        }
    }

    private description() {
        const description = this.props.question.description;
        if (description !== undefined) {
            return <p>{description}</p>;
        } else {
            return null;
        }
    }

    optionChanged = (selected: Map<Answer, number>) => {
        this.setState({selected});
        this.runOnChange(selected, this.state.count);
    }

    dividerChanged = (count: number) => {
        this.setState({count});
        this.runOnChange(this.state.selected, count);
    }

    private runOnChange(selected: Map<Answer, number>, count: number) {
        const points = Array.from(selected.values())
            .reduce((previousValue, currentValue) => previousValue + (currentValue / count), this.props.question.initial || 0);
        this.props.onChange(points);
    }
}

export default QuestionDiv;
