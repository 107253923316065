import React from "react";
import RangeInput from "./Range";
import {RangeAnswer} from "../../model";

type Props = {
    id: string;
    answer: RangeAnswer;
    count: number;
    onChange: (count: number) => void;
}

const AnswerCounter = ({id, answer, count, onChange}: Props) => {
    return <li className="list-group-item">
        <RangeInput id={id}
                    title={answer.title}
                    min={0}
                    count={count}
                    step={answer.step}
                    unit={answer.unit}
                    max={answer.max}
                    onChange={count => onChange(count)}/>
    </li>;
}

export default AnswerCounter;
