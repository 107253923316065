import React from "react";
import {Link} from "react-router-dom";

import "./Landing.scss";
import ContactForm from "./ContactForm";
import DownloadForm from "./DownloadForm";
import Header from "./Header";
import Steps from "./Steps";


export const downloadElementId = "pobieranie";

function imageSrc(path: String) {
    return process.env.PUBLIC_URL + path;
}

const WhatIs = () => <>
    <h2>
        Czym jest <strong>ślad ekologiczny?</strong>
    </h2>
    <div className="row">
        <div className="col col-md-9">
            <p>
                Każde nasze działanie wymaga
                korzystania z określonych zasobów środowiskowych
                i jednocześnie powoduje skutki, których doświadcza środowisko.
            </p>
            <p>
                To, ile bierzemy w postaci zasobów oraz ile oddajemy w postaci odpadów, można skonfrontować
                z możliwościami ziemskiego ekosystemu w zakresie zaspokajania naszych potrzeb.
            </p>
            <p>
                <strong>Ślad ekologiczny</strong> jest miarą naszego zapotrzebowania na zasoby biosfery. Pozwala
                ocenić,
                czy zachowujemy równowagę między tym, ile bierzemy, a ile Ziemia jest w stanie nam dać.
            </p>
            <p>
                Jest wykorzystywany zarówno przez naukowców, jak i przez polityków, którzy podejmują decyzje
                w oparciu o kontekst środowiskowy, np. w ONZ czy w Unii Europejskiej.
            </p>
        </div>
    </div>
</>;

const Factors = () => <>
    <h2 className="text-center mb-5">
        Składowe śladu ekologicznego
    </h2>
    <ul className="factors row row-cols-2 row-cols-md-3 list-unstyled text-center fw-bold fs-5 gy-5 mb-5">
        <li className="factors-diet col">
            Dieta
        </li>
        <li className="factors-electricity col">
            Elektryczność
        </li>
        <li className="factors-transport col">
            Transport
        </li>
        <li className="factors-housing col">
            Mieszkanie
        </li>
        <li className="factors-travel col">
            Podróże
        </li>
        <li className="factors-appliances col">
            Media
        </li>
    </ul>
</>;

const Calculator = () => {
    return <div className="row row-cols-md-2">
        <div className="col">
            <h2>
                E-kalkulator <strong>śladu ekologicznego</strong>
            </h2>
            <p>
                We współpracy z naukowcami stworzyliśmy kalkulator, który pozwala obliczyć roczny ślad
                ekologiczny
                (środowiskowy).
            </p>
            <p>
                Klikając poniżej, możesz obliczyć swój ślad, a w następnej sekcji znajdziesz materiały do
                poprowadzenia zajęć z naszym kalkulatorem.
            </p>
            <p className="mt-5 text-center">
                <Link to="/kalkulator/" className="btn btn-primary">Oblicz swój ślad</Link>
            </p>
        </div>
        <div className="col text-center bg-secondary bg-opacity-10 py-2">
            <Link to="/kalkulator/">
                <img src={imageSrc("/calculator-app.png")}
                     height={546}
                     width={280}
                     alt="Zrzut ekranu aplikacji kalkulatora"/>
            </Link>
        </div>
    </div>;
};

const Download = () =>
    <div className="row row-cols-md-2">
        <div className="col">
            <h4>
                Materiały edukacyjne <span className="line"></span>
            </h4>
            <h2>
                Wsparcie dla <strong>nauczycieli</strong>
            </h2>
            <p>
                Do kalkulatora dołączamy materiały przeznaczone do przeprowadzenia godziny wychowawczej na temat
                ekologii.
            </p>
            <ul className="download-features list-unstyled">
                <li>scenariusze lekcji wychowawczych</li>
                <li>e-kalkulator śladu ekologicznego dla uczniów</li>
                <li>klucz do interpretacji wyników e-kalkulatora</li>
                <li>kontekst pojęcia “dzień długu ekologicznego”</li>
                <li>wskazówki, jak zmotywować uczniów</li>
                <li>wskazówki, jak pracować z oporem uczniów</li>
                <li>dodatkowe materiały opracowane przez ekologów oraz psychologów</li>
                <li>FAQ</li>
            </ul>
        </div>
        <div className="col px-5 py-3">
            <p className="lead text-center">
                Skontaktuj się z nami, by otrzymać materiały edukacyjne
            </p>
            <DownloadForm/>
        </div>
    </div>;


const Opinions = () => <div className="py-5">
    <h2 className="text-center mb-5">
        Opinie o kalkulatorze
    </h2>
    <figure className="widget widget-text text-center opinions-lead mb-5">
        <blockquote>
            Po przeprowadzeniu pierwszej lekcji z e-kalkulatorem widzę, że potrzebna jest rzetelna edukacja
            w tym temacie, aby wzrosła świadomość ekologiczna młodzieży i dorosłych.
        </blockquote>
        <figcaption className="fs-5 fw-bolder">Nauczyciel szkoły społecznej w Katowicach</figcaption>
    </figure>
    <div className="row row-cols-md-2">
        <figure className="widget widget-text">
            <blockquote>
                Bardzo ciekawa lekcja, teraz chcę się jeszcze więcej dowiedzieć, jak mogę zużywać mniej energii
                i kupować bardziej ekologiczne produkty.
            </blockquote>
            <figcaption className="fs-5 fw-bolder">Uczeń VI LO Katowice</figcaption>
        </figure>
        <figure className="widget widget-text">
            <blockquote>
                Wow, to robi wielkie wrażenie i czuję chęć zmiany po policzeniu mojego śladu ekologicznego!
            </blockquote>
            <figcaption className="fs-5 fw-bolder">Uczennica I LO Siemianowice Śląskie</figcaption>
        </figure>
    </div>
</div>;

const Organizers = () => {
    return <>
        <h2 className="text-center">
            Organizatorzy
        </h2>
        <ul className="row list-unstyled partners">
            <li className="col d-flex align-self-center">
                <img src={imageSrc("/logo-srk.jpeg")} alt="Śląski Ruch Klimatyczny" className="mx-auto"/>
            </li>
            <li className="col d-flex align-self-center">
                <img src={imageSrc("/logo-frs.png")} alt="Fundacja Rzecz Społeczna" className="mx-auto"/>
            </li>
            <li className="col d-flex align-self-center">
                <img src={imageSrc("/logo-kme.jpeg")} alt="Klub Myśli Ekologicznej" className="mx-auto"/>
            </li>
        </ul>
    </>;
}

const Partners = () => {
    return <>
        <h2 className="text-center">
            Partnerzy
        </h2>
        <ul className="row list-unstyled partners">
            <li className="col d-flex align-self-center">
                <img src={imageSrc("/logo-ed.png")} alt="Europe Direct" className="mx-auto"/>
            </li>
            <li className="col d-flex align-self-center">
                <img src={imageSrc("/logo-so.png")} alt="Śląska Opinia" className="mx-auto"/>
            </li>
            <li className="col d-flex align-self-center">
                <img src={imageSrc("/logo-katowice.jpg")} alt="Miasto Katowice" className="mx-auto"/>
            </li>
        </ul>
    </>;
};

const Contact = () =>
    <div className="row row-cols-md-2">
        <div className="col col-12 col-md-6">
            <h2>
                Skontaktuj się <strong>z nami</strong>
            </h2>
            <p>
                Skorzystaj z formularza, jeśli masz więcej pytań.
            </p>
        </div>
        <div className="col col-12 col-md-6">
            <ContactForm/>
        </div>
    </div>;

const Landing = () => <>
    <section id="header">
        <div className="container dark">
            <Header downloadElementId={downloadElementId}/>
        </div>
    </section>
    <section className="container my-5">
        <WhatIs/>
    </section>
    <section className="container my-5">
        <Factors/>
    </section>
    <section className="container my-5">
        <Calculator/>
    </section>
    <section id={downloadElementId} className="container my-5">
        <Download/>
    </section>
    <section id="steps" className="container my-5">
        <Steps/>
    </section>
    <section id="opinions">
        <div className="container my-5">
            <Opinions/>
        </div>
    </section>
    <section className="container my-5">
        <Organizers/>
        <Partners/>
    </section>
    <footer id="contact" className="dark">
        <div className="container py-5">
            <Contact/>
        </div>
    </footer>
</>

export default Landing;
