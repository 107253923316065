import React from "react";

type State = {
    input: string;
}

type Props = {
    id: string;
    title: string;
    min: number;
    count: number;
    step: number;
    max: number;
    unit?: string;
    onChange: (count: number) => void;
}

const MinusIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                className="bi bi-dash-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
    </svg>;
}

const PlusIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                className="bi bi-plus-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
    </svg>;
}

class RangeInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            input: props.count.toLocaleString(undefined, {maximumFractionDigits: 0}),
        };
    }

    render() {
        return <div className="row g-2 align-items-center">
            <div className="col-md-4">
                <label className={"col-form-label"}
                       htmlFor={this.props.id}>
                    {this.props.title}
                </label>
            </div>
            <div className="col">
                {this.plusMinus()}
                <input type="range"
                       className="form-range d-none d-md-inline-block"
                       min={this.props.min}
                       value={this.props.count}
                       step={this.props.step}
                       max={this.props.max}
                       onChange={(e: React.FormEvent<HTMLInputElement>) => this.onChange(parseFloat(e.currentTarget.value))}/>
            </div>
            <div className="col-md-1 text-center d-none d-md-block">
                {this.props.count.toLocaleString(undefined, {maximumFractionDigits: 1})}
                {this.props.count === this.props.max ? "+" : ""}
                {this.props.unit === undefined ? null : <span> {this.props.unit}</span>}
            </div>
        </div>;
    }

    private plusMinus() {
        return <div className="input-group w-50 mx-auto d-md-none">
            <button type="button"
                    className="btn btn-danger"
                    disabled={this.props.count === this.props.min}
                    onClick={() => this.changeByStep(-1)}>
                <MinusIcon/>
            </button>
            <input type="number"
                   className="form-control text-center"
                   min={this.props.min}
                   value={this.state.input}
                   step={this.props.step}
                   max={this.props.max}
                   onFocus={(e: React.FormEvent<HTMLInputElement>) => e.currentTarget.select()}
                   onChange={(e: React.FormEvent<HTMLInputElement>) => this.onInput(e.currentTarget.value)}
                   onBlur={(e: React.FormEvent<HTMLInputElement>) => this.onChange(parseFloat(e.currentTarget.value) || this.props.count)}/>
            <button type="button"
                    className="btn btn-success"
                    disabled={this.props.count === this.props.max}
                    onClick={() => this.changeByStep(1)}>
                <PlusIcon/>
            </button>
        </div>;
    }

    onInput(input: string) {
        this.setState({input});
    }

    trim(value: number) {
        return Math.max(this.props.min, Math.min(this.props.max, value));
    }

    changeByStep(multiplier: number) {
        this.onChange(this.props.count + multiplier * this.props.step);
    }

    private onChange(next: number) {
        const input = this.trim(next);
        this.props.onChange(input);
        this.setState({input: input.toLocaleString(undefined, {maximumFractionDigits: 0})});
    }
}

export default RangeInput;
