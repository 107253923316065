import React from "react";
import {sendContact} from "../lambda/lambdaClient";

type Props = {};

type State = {
    validated: boolean;
    pending: boolean;
    sent: boolean;
    error: boolean;
    name: string;
    text: string;
}

class ContactForm extends React.Component<Props, State> {
    state = {
        validated: false,
        pending: false,
        sent: false,
        error: false,
        name: "",
        text: "",
    };

    render() {
        return <form method="post"
                     className={"px-5" + (this.state.validated ? " was-validated" : "")}
                     noValidate={true}
                     onSubmit={(ev) => this.submit(ev)}>
            <p>
                <label className="form-label w-100">
                    * Imię i nazwisko
                    <input name="name"
                           type="text"
                           className="form-control"
                           placeholder="Imię i nazwisko"
                           value={this.state.name}
                           required={true}
                           onChange={(ev) => this.setState({name: ev.target.value})}
                           disabled={this.isDisabled()}/>
                    <span className="invalid-feedback">
                        Pole wymagane
                    </span>
                </label>
            </p>
            <p>
                <label className="form-label w-100">
                    * Wiadomość
                    <textarea name="text"
                              className="form-control"
                              rows={4}
                              value={this.state.text}
                              required={true}
                              onChange={(ev) => this.setState({text: ev.target.value})}
                              disabled={this.isDisabled()}></textarea>
                    <span className="invalid-feedback">
                        Pole wymagane
                    </span>
                </label>
            </p>
            <p className="text-center">
                <button type="submit" className="btn btn-primary" disabled={this.isDisabled()}>
                    {this.state.sent ? "Wysłano" : "Wyślij"}
                </button>
            </p>
            {this.summary()}
        </form>;
    }

    summary() {
        if (this.state.error) {
            return <p>Wystąpił błąd, prosimy spróbować ponownie.</p>
        } else if (this.state.sent) {
            return <p>Dziękujemy za przesłanie wiadomości.</p>
        } else {
            return <></>;
        }
    }

    isDisabled() {
        return this.state.pending || this.state.sent;
    }

    submit(ev: React.FormEvent<HTMLFormElement>) {
        if (this.isDisabled()) {
            return;
        }
        ev.preventDefault();
        this.setState({validated: true});
        if (!ev.currentTarget.checkValidity()) {
            return;
        }
        this.setState({
            pending: true,
            error: false,
        });
        sendContact(this.state.name, this.state.text)
            .then(output => {
                if (output.FunctionError) {
                    this.onError(output);
                } else {
                    console.log(output);
                    this.setState({
                        pending: false,
                        sent: true,
                    });
                }
            })
            .catch(error => {
                this.onError(error);
            });
    }

    private onError(error: any) {
        console.error(error);
        this.setState({
            pending: false,
            error: true,
        });
    }
}

export default ContactForm;
