import React from "react";

import {PageId} from "./paging/Page";
import Next from "./paging/Next";
import Score from "./Score";

type Props = {
    selectedPage: PageId;
    pages: PageId[];
    points: number;
    onChange: (title: PageId) => void;
}

const QuestionNav = ({selectedPage, pages, points, onChange}: Props) => {
    return <div className={"d-flex justify-content-between my-3"}>
        <Score points={points}
               format="short"/>
        <Next page={selectedPage}
              pages={pages}
              onChange={page => onChange(page)}/>
    </div>;
}

export default QuestionNav;
