import React from 'react';
import {Answer, Question, QuestionSection, RangeAnswer} from './model';
import {QuestionForm} from './QuestionForm';
import Content from "./ui/layout/Content";

function addPoints(points: number) {
    return `na początek kalkulator dodał Ci ${points} pkt. Zaznaczając poniższe odpowiedzi, odejmiesz od tej sumy:`;
}

function transport(mean: string, local: number, foreign: number): Question {
    return {
        description: `Podróżowałeś(-aś) ${mean} po:`,
        answers: [
            new RangeAnswer("Europie", foreign),
            new RangeAnswer("Polsce", local),
        ],
    };
}

function App() {
    const answers1: RangeAnswer[] = [
        new RangeAnswer("Kąpiesz się w wannie", 2),
        new RangeAnswer("Bierzesz prysznic (do 5 minut)", 1),
        new RangeAnswer("Używasz zmywarki do naczyń", 0.5),
        new RangeAnswer("Zmywasz pod bieżącą wodą, nie ograniczając jej zużycia", 0.5),
        new RangeAnswer("Zmywasz pod bieżącą wodą, starając się maksymalnie ograniczyć jej zużycie", 0.25),
        new RangeAnswer("Myjesz samochód w myjni bezdotykowej (5 minut)", 2),
    ];
    const question1: QuestionSection = {
        title: "Woda",
        questions: [{
            description: "Wybierz ile razy w ciągu tygodnia:",
            answers: answers1,
            multi: true,
        }],
    };
    const question2a: Question = {
        description: "Pochodzenie produktów:",
        answers: [
            new Answer("Robiąc zakupy kupujesz świeże produkty pochodzące z twojego regionu", 2),
            new Answer("Około połowy twoich zakupów stanowią produkty egzotyczne i/lub przetworzone", 14),
            new Answer("Nie zwracasz uwagi na to skąd pochodzą produkty i jak są przetworzone", 25),
        ]
    };
    const question2b: Question = {
        description: "Dieta:",
        answers: [
            new Answer("Jesz codziennie mięso", 85),
            new Answer("W twojej diecie przeważają produkty bezmięsne", 30),
            new Answer("Jesteś wegetarianinem (nie jesz mięsa i ryb)", 10),
            new Answer("Jesteś weganinem (nie jesz mięsa, ryb nabiału i miodu)", 4),
        ]
    };
    const question2: QuestionSection = {
        title: "Jedzenie",
        questions: [question2a, question2b],
    };
    const answers3: Answer[] = [
        new Answer("Przy diecie mięsnej", 10),
        new Answer("W twojej diecie przeważają produkty bezmięsne", 4),
        new Answer("Jesteś wegetarianinem (nie jesz mięsa i ryb)", 1),
        new Answer("Jesteś weganinem (nie jesz mięsa, ryb nabiału i miodu)", 0.5),
    ];
    const question3: QuestionSection = {
        title: "Marnowanie jedzenia",
        description: "Wyrzucasz w skali tygodnia 1,3 kg jedzenia lub więcej",
        questions: [{answers: answers3}],
    };
    const answers4: Answer[] = [
        new Answer("Kawalerce lub małym mieszkaniu w bloku", 7),
        new Answer("Domku szeregowym", 15),
        new Answer("Dużym mieszkaniu w bloku", 12),
        new Answer("Wolno stojącym domu", 33),
    ];
    const question4: QuestionSection = {
        title: "Mieszkanie",
        questions: [{
            description: "Mieszkasz w:",
            answers: answers4,
            divider: "Jeśli nie mieszkasz sam(-a), podziel punkty przez liczbę osób:",
        }],
    };
    const questions5: Question[] = [
        {
            description: "Poleciałeś(-aś) samolotem:",
            answers: [
                new RangeAnswer("Na inny kontynent", 85),
                new RangeAnswer("Do kraju europejskiego", 30),
            ],
        },
        transport("samochodem", 15, 20),
        transport("autobusem", 7, 9),
        transport("pociągiem", 4, 5),
    ];
    const section5: QuestionSection = {
        title: "Wakacje",
        description: "Wskaż ile razy, w ciągu ostatniego roku, korzystałeś(-aś) z następujących środków transportu (przejazdy/przeloty w obie strony):",
        questions: questions5,
    };
    const answers6: Answer[] = [
        new Answer("Twój dom ma dobrą izolację", -15),
        new Answer("Masz szczelne okna", -5),
        new Answer("Ogrzewanie włączasz, gdy to jest konieczne, przykręcasz termostat na minimum", -10),
    ];
    const question6: QuestionSection = {
        title: "Ogrzewanie domu",
        questions: [{
            description: "Abyś miał(-a) ciepło w domu, spalane są kopaliny i jest uwalniany do atmosfery dwutlenek węgla. Dlatego " + addPoints(45),
            answers: answers6,
            initial: 45,
            multi: true,
        }],
    };
    const answers7: RangeAnswer[] = [
        new RangeAnswer("Ile godzin w tygodniu przeglądasz internet w formie streamingu (YouTube, filmy, spotkania online, gry wideo)?", 1, 5, 50, "godzin"),
    ];
    const question7: QuestionSection = {
        title: "Internet",
        description: "",
        questions: [{
            answers: answers7,
        }],
    };
    const answers8a: Answer[] = [
        new Answer("Gasisz światło wychodząc z pokoju", -10),
        new Answer("Wyłączasz tryb czuwania w różnych urządzeniach (telewizor, drukarka, sprzęt grający)", -5),
        new Answer("Korzystasz z urządzeń AGD kiedy są pełne (pralka, zmywarka, piekarnik)", -10),
        new Answer("Całkowicie zrezygnowałeś(-aś) z niektórych urządzeń AGD (piekarnik, suszarka do ubrań, zmywarka, telewizor)", -20),
    ];
    const answer8switch = new Answer("Nie", 65);
    const answers8b: Answer[] = [
        new Answer("Tak", 15),
        answer8switch,
    ];
    const question8a = {
        description: "Czerpiesz energię głównie z odnawialnych źródeł energii.", // punkty?
        answers: answers8b,
        multi: false,
    };
    const question8b = {
        description: "Podczas produkcji energii uwalniany jest dwutlenek węgla, w związku z tym " + addPoints(65),
        answers: answers8a,
        multi: true,
        when: {question: question8a, answer: answer8switch},
    };
    const question8: QuestionSection = {
        title: "Elektryczność",
        questions: [question8a, question8b],
    };
    const answers9a: RangeAnswer[] = [
        new RangeAnswer("Samochodem o spalaniu 4-6 l / 100 km lub elektryczny", 40 / 25, 5, 200, "km"),
        new RangeAnswer("Samochodem o spalaniu 7–9 l / 100 km", 50 / 25, 5, 200, "km"),
        new RangeAnswer("Samochodem o spalaniu powyżej 9 l / 100 km", 75 / 25, 5, 200, "km"),
        new RangeAnswer("Transportem publicznym", 25 / 25, 2.5, 100, "km"),
        new RangeAnswer("Rowerem", 5 / 25, 1, 30, "km"),
    ];
    const answers9b: RangeAnswer[] = [
        new RangeAnswer("Lecąc samolotem do kraju europejskiego", 30 / 25, 100, 5000, "km"),
    ];
    const question9: QuestionSection = {
        title: "Codzienne podróże",
        questions: [{
            description: "Ile kilometrów dziennie pokonujesz tymi środkami transportu:",
            answers: answers9a,
        }, {
            description: "Ile kilometrów tygodniowo pokonujesz tymi środkami transportu:",
            answers: answers9b,
        }],
    };
    const answers10: Answer[] = [
        new Answer("Segregujesz śmieci", -20),
        new Answer("Kompostujesz odpadki kuchenne", -5),
        new Answer("Unikasz kupowania puszek", -10),
        new Answer("Unikasz kupowania jednorazowych opakowań szklanych", -5),
        new Answer("Unikasz toreb i opakowań plastikowych", -5),
        new Answer("Ograniczasz konsumpcję i w ten sposób produkujesz mniej śmieci (wydajesz średnio nie więcej niż 500 zł na osobę miesięcznie na wszystkie zakupy poza jedzeniem)", -45),
    ];
    const question10: QuestionSection = {
        title: "Odpady",
        questions: [{
            description: "Wyrzucając śmieci, zużywasz cenny teren, dlatego " + addPoints(100),
            answers: answers10,
            initial: 100,
            multi: true,
        }],
    };
    const sections: QuestionSection[] = [
        question1,
        question2,
        // question3,
        question4,
        question9,
        section5,
        question6,
        question7,
        question8,
        question10];
    const checked: Map<Question, Set<Answer>> = new Map<Question, Set<Answer>>();
    sections.flatMap(section => section.questions)
        .forEach(question => checked.set(question, new Set<Answer>()));

    return <Content>
        <QuestionForm sections={sections}/>
    </Content>;
}

export default App;
