import React from "react";
import Layout from "./Layout";

const PrivacyPolicy = () => <Layout>
    <section className={"container my-5"}>
        <h3>Polityka prywatności</h3>
        <ol>
            <li>Administratorem danych osobowych jest FUNDACJA KLUB MYŚLI EKOLOGICZNEJ ul. Jagiełły 33C/19, 41-106
                Siemianowice Śląskie, zarejestrowana w rejestrze stowarzyszeń prowadzonym przez Sąd Rejonowy
                Katowice-Wschód w Katowicach Wydział VIII Gospodarczy Krajowego Rejestru Sądowego pod nr KRS:
                0000874997, REGON: 387945491, NIP: 6431774873.
            </li>
            <li>Dane osobowe są przetwarzane na podstawie art. 6 ust.1 lit. b) Rozporządzenia Parlamentu Europejskiego i
                Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w celu przekazywania materiałów dydaktycznych dla
                nauczycieli.
            </li>
            <li>Dane osobowe będą przechowywane przez okres 5 lat, następnie zostaną usunięte z systemu.</li>
            <li>Wykonawca ma prawo żądać od Administratora danych dostępu do swoich danych osobowych, ich sprostowania,
                usunięcia lub ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania, żądania
                przeniesienia danych, a także prawo wniesienia skargi do organu nadzorczego.
            </li>
            <li>Podanie danych osobowych jest warunkiem pobrania materiałów.</li>
        </ol>
    </section>
</Layout>;

export default PrivacyPolicy;
