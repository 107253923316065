export type QuestionCondition = {
    answer: Answer;
    question: Question;
};

export type Question = {
    answers: Answer[] | RangeAnswer[];
    description?: string;
    initial?: number;
    multi?: boolean;
    divider?: string;
    when?: QuestionCondition;
};

export type QuestionSection = {
    title: string;
    description?: string;
    questions: Question[];
};

export class Answer {
    title: string;
    points: number;

    constructor(title: string, points: number) {
        this.title = title;
        this.points = points;
    }
}

export class RangeAnswer {
    title: string;
    points: number;
    step: number;
    max: number;
    unit?: string;

    constructor(title: string, points: number, step: number = 1, max: number = 10, unit?: string) {
        this.title = title;
        this.points = points;
        this.step = step;
        this.max = max;
        this.unit = unit;
    }
}
