import React from 'react';

type Props = {
    points: number;
    format: "short" | "full";
};

function asString(points: number) {
    return points.toLocaleString(undefined, {maximumFractionDigits: 1});
}

const Score = ({points, format}: Props) => {
    if (format === "full") {
        return <p>
            Suma Twoich punktów: <strong>{asString(points)}</strong>.
        </p>;
    } else {
        return <span>
            Twój wynik: {asString(points)}
        </span>;
    }
};

export default Score;
