import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import Landing from "./landing/Landing";
import reportWebVitals from "./reportWebVitals";
import {createBrowserRouter, RouterProvider, useLocation} from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";

export type Props = {
    children: React.ReactNode;
};

const ScrollFix = ({children}: Props) => {
    const {pathname, hash, key} = useLocation();

    React.useEffect(() => {
        requestAnimationFrame(() => {
            if (hash === '') {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            } else {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }
        });
    }, [pathname, hash, key]);

    return <>{children}</>;
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <ScrollFix><Landing/></ScrollFix>,
    },
    {
        path: "/kalkulator/",
        element: <ScrollFix><App/></ScrollFix>,
    },
    {
        path: "/polityka-prywatnosci/",
        element: <ScrollFix><PrivacyPolicy/></ScrollFix>,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
