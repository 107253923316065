import React from "react";
import {PageId} from "./Page";

type Props = {
    selectedPage: PageId
    pages: Array<PageId>
    onChange: (title: PageId) => void
}

const Navigation = ({selectedPage, pages, onChange}: Props) => {
    function onClick(e: React.MouseEvent, page: PageId) {
        e.preventDefault();
        onChange(page);
    }

    return <div>
        <hr/>
        <ul className="nav nav-pills justify-content-center">
            {pages.map(page => {
                const linkClasses = (selectedPage === page) ? "active" : "";
                return <li key={page}
                           className="nav-item">
                    <a className={"nav-link " + linkClasses}
                       href={`#${page}`}
                       onClick={(e: React.MouseEvent) => onClick(e, page)}>
                        {page}
                    </a>
                </li>;
            })}
        </ul>
    </div>;
}

export default Navigation;
