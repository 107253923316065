import React from "react";
import {Link} from "react-router-dom";

import {downloadElementId} from "../../landing/Landing";

const Footer = () => {
    return <div className="text-center">
        <Link to={`/#${downloadElementId}`}
              className="btn btn-primary">
            Pobierz materiały edukacyjne
        </Link>
    </div>;
};

export default Footer;
