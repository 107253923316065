import React from "react";
import {Link} from "react-router-dom";

import {sendDownload} from "../lambda/lambdaClient";

type Props = {};

type State = {
    validated: boolean;
    pending: boolean;
    sent: boolean;
    error: boolean;
    name: string;
    email: string;
    city: string;
    school: string;
    gdpr: boolean;
}

const DOWNLOAD = "download_scenarios";

class DownloadForm extends React.Component<Props, State> {
    state = {
        validated: false,
        pending: false,
        sent: false,
        error: false,
        name: "",
        email: "",
        city: "",
        school: "",
        gdpr: false,
    };

    render() {
        return <form method="post"
                     className={"px-5" + (this.state.validated ? " was-validated" : "")}
                     noValidate={true}
                     onSubmit={(ev) => this.submit(ev)}>
            <p>
                <label className="form-label w-100">
                    * Imię i nazwisko
                    <input name="name"
                           type="text"
                           className="form-control"
                           placeholder="Imię i nazwisko"
                           value={this.state.name}
                           required={true}
                           onChange={(ev) => this.setState({name: ev.target.value})}
                           disabled={this.isDisabled()}/>
                    <span className="invalid-feedback">
                        Pole wymagane
                    </span>
                </label>
            </p>
            <p>
                <label className="form-label w-100">
                    * Adres e-mail
                    <input name="email"
                           type="email"
                           className="form-control"
                           placeholder="Adres e-mail"
                           value={this.state.email}
                           required={true}
                           onChange={(ev) => this.setState({email: ev.target.value})}
                           disabled={this.isDisabled()}
                    />
                    <span className="invalid-feedback">
                        Pole wymagane
                    </span>
                </label>
            </p>
            <p>
                <label className="form-label w-100">
                    * Miasto, gdzie pracujesz
                    <input name="city"
                           type="text"
                           className="form-control"
                           placeholder="Miasto, gdzie pracujesz"
                           value={this.state.city}
                           required={true}
                           onChange={(ev) => this.setState({city: ev.target.value})}
                           disabled={this.isDisabled()}/>
                </label>
            </p>
            <p>
                <label className="form-label w-100">
                    * Szkoła/instytucja
                    <input name="school"
                           type="text"
                           className="form-control"
                           placeholder="Szkoła/instytucja"
                           value={this.state.school}
                           required={true}
                           onChange={(ev) => this.setState({school: ev.target.value})}
                           disabled={this.isDisabled()}/>
                </label>
            </p>
            <p>
                <label className="form-check-label form-text w-100">
                    <input name="gdpr"
                           type="checkbox"
                           className="form-check-input"
                           checked={this.state.gdpr}
                           required={true}
                           onChange={(ev) => this.setState({gdpr: ev.target.checked})}
                           disabled={this.isDisabled()}/>
                    &nbsp; Wyrażam zgodę na przetwarzanie moich danych osobowych w celu otrzymania materiałów
                    edukacyjnych. <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>.
                    <span className="invalid-feedback">
                        Zgoda jest wymagana
                    </span>
                </label>
            </p>
            <p className="text-center">
                <button type="submit"
                        className="btn btn-primary"
                        disabled={this.isDisabled()}>
                    {this.state.sent ? "Wysłano" : "Wyślij"}
                </button>
            </p>
            {this.summary()}
            <a id={DOWNLOAD}
               className="d-none"
               download
               href={`${process.env.PUBLIC_URL}/download/${encodeURIComponent("Kalkulator Śladu Ekologicznego - Materiały.zip")}`}>
                Materiały do pobrania
            </a>
        </form>;
    }

    summary() {
        if (this.state.error) {
            return <p>Wystąpił błąd, prosimy spróbować ponownie.</p>
        } else if (this.state.sent) {
            return <p>Dziękujemy za pobranie materiałów.</p>
        } else {
            return <></>;
        }
    }

    isDisabled() {
        return this.state.pending || this.state.sent;
    }

    submit(ev: React.FormEvent<HTMLFormElement>) {
        if (this.isDisabled()) {
            return;
        }
        ev.preventDefault();
        this.setState({validated: true});
        if (!ev.currentTarget.checkValidity()) {
            return;
        }
        this.setState({
            pending: true,
            error: false,
        });
        sendDownload(this.state.name, this.state.email, this.state.city, this.state.school)
            .then(output => {
                if (output.FunctionError) {
                    this.onError(output);
                } else {
                    console.log(output);
                    window.document.getElementById(DOWNLOAD)!.click();
                    this.setState({
                        pending: false,
                        sent: true,
                    });
                }
            })
            .catch(error => {
                this.onError(error);
            });
    }

    private onError(error: any) {
        console.error(error);
        this.setState({
            pending: false,
            error: true,
        });
    }
}

export default DownloadForm;
