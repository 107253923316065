import React from "react";
import {Answer, Question, RangeAnswer} from "../../model";
import AnswerCheckbox from "./AnswerCheckbox";
import AnswerRadio from "./AnswerRadio";
import AnswerCounter from "./AnswerCounter";

type State = {
    count: number
}

type Props = {
    questionId: string
    answerId: string;
    answer: Answer | RangeAnswer;
    question: Question;
    checked: Map<Answer, number>;
    onChange: (answer: Map<Answer, number>) => void;
}

class AnswerInput extends React.Component<Props, State> {
    state = {
        count: 0,
        selected: new Set<Answer>(),
    };

    render() {
        if (this.props.answer instanceof RangeAnswer) {
            return <AnswerCounter key={this.props.answerId}
                                  id={this.props.answerId}
                                  count={this.getCount()}
                                  answer={this.props.answer}
                                  onChange={this.counterChanged}/>
        } else if (this.props.question.multi) {
            return <AnswerCheckbox key={this.props.answerId}
                                   answer={this.props.answer}
                                   checked={this.props.checked.has(this.props.answer)}
                                   onChange={this.checkboxChanged}/>;
        } else {
            return <AnswerRadio key={this.props.answerId}
                                name={this.props.questionId}
                                answer={this.props.answer}
                                checked={this.props.checked.has(this.props.answer)}
                                onChange={this.radioChanged}/>;
        }
    }

    private getCount() {
        return (this.props.checked.get(this.props.answer) || 0) / this.props.answer.points;
    }

    counterChanged = (count: number) => {
        const updated = new Map(this.props.checked);
        updated.set(this.props.answer, this.props.answer.points * count);
        this.props.onChange(updated);
    }

    checkboxChanged = (checked: boolean) => {
        const updated = new Map(this.props.checked);
        if (checked) {
            updated.set(this.props.answer, this.props.answer.points);
        } else {
            updated.delete(this.props.answer);
        }
        this.props.onChange(updated);
    }

    radioChanged = () => {
        const updated = new Map();
        updated.set(this.props.answer, this.props.answer.points);
        this.props.onChange(updated);
    }
}

export default AnswerInput;
