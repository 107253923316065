import React from "react";
import {HeaderSmall} from "./landing/Header";
import Footer from "./ui/layout/Footer";

export type Props = {
    children: React.ReactNode;
};

const Layout = ({children}: Props) => <>
    <section id="header">
        <div className="container dark">
            <HeaderSmall/>
        </div>
    </section>
    {children}
    <footer id="contact" className="dark">
        <div className="container py-5">
            <Footer/>
        </div>
    </footer>
</>;

export default Layout;
