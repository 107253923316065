import React from "react";
import {Link} from "react-router-dom";

import './Header.scss';

function imageSrc(path: String) {
    return process.env.PUBLIC_URL + path;
}

type Props = {
    downloadElementId: string;
};

const Header = ({downloadElementId}: Props) => <div className="position-relative row">
    <div className="header-organizer col col-12 d-none d-md-block">
        <h3>Śląski Ruch Klimatyczny</h3>
    </div>
    <div className="col col-12 order-md-1">
        <h1 className="my-4">Kalkulator Śladu <strong>Ekologicznego</strong></h1>
    </div>
    <div className="col col-12 order-md-0">
        <h4>ZWIĘKSZ, żeby zmniejszyć! <span className="line"></span></h4>
    </div>
    <div className="col col-12 order-md-2 my-4 header-lead">
        <p>
            Materiały edukacyjne dla nauczycieli na godzinę wychowawczą.<br/>
            ZWIĘKSZ wiedzę swoją i uczniów, żeby zmniejszyć ślad ekologiczny.
        </p>
    </div>
    <div className="col col-12 header-footprint-logo">
        <img src={imageSrc("/footprint.svg")} alt="Logo"/>
    </div>
    <div className="col col-12 order-last">
        <a href={"#" + downloadElementId} className="header-cta btn btn-primary">
            Dowiedz się więcej
        </a>
    </div>
</div>;

export default Header;

export const HeaderSmall = () =>
    <Link to="/">
        <h1 className="py-md-4">
            Kalkulator Śladu <strong>Ekologicznego</strong>
        </h1>
    </Link>;
