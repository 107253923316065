import React from "react";

import Navigation from "./Navigation";
import {Page, PageId} from "./Page";
import {ContainerId} from "../layout/Content";

type State = {}

type Props = {
    selectedPage: string
    pages: Array<Page>
    onChange: (title: PageId) => void
}

export class Pages extends React.Component<Props, State> {
    componentDidUpdate(prevPropsProps: Props) {
        if (this.props.selectedPage !== prevPropsProps.selectedPage) {
            Pages.scrollIntoView();
        }
    }

    private static scrollIntoView() {
        const container = document.getElementById(ContainerId);
        if (container !== null) {
            container.scrollIntoView();
        }
    }

    renderPage(page: Page) {
        const classes = page.title === this.props.selectedPage ? "" : "d-none";
        return <div className={classes} key={page.title}>
            <h2>{page.title}</h2>
            {page.children}
        </div>;
    }

    render() {
        return <div>
            {this.props.pages.map((page) => this.renderPage(page))}
            <Navigation selectedPage={this.props.selectedPage}
                        pages={this.props.pages.map(p => p.title)}
                        onChange={page => this.props.onChange(page)}/>
        </div>;
    }
}

export default Pages;
