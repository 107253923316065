import React from "react";
import Layout from "../../Layout";

type Props = {
    children: React.ReactNode;
}

export const ContainerId = "efc-container"

const Content = ({children}: Props) =>
    <Layout>
        <section id={ContainerId}
                 className="container py-3">
            {children}
        </section>
    </Layout>;

export default Content;
