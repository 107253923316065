import {InvokeCommand, LambdaClient} from "@aws-sdk/client-lambda";
import {fromCognitoIdentityPool} from "@aws-sdk/credential-provider-cognito-identity";
import {CognitoIdentityClient} from "@aws-sdk/client-cognito-identity";

const REGION = "eu-north-1";
const LAMBDA_PREFIX = process.env.REACT_APP_LAMBDA_PREFIX || "efc-test";
const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID || "eu-north-1:e32bad49-1cfa-4198-86ca-be5f03f564ff";

// Create an Amazon DynamoDB client service object that initializes the Amazon Cognito credentials provider.
const lambdaClient = new LambdaClient({
    region: REGION,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({region: REGION}),
        identityPoolId: IDENTITY_POOL_ID,
    }),
});

const sendContact = (name: string, text: string) => {
    const params = {
        FunctionName: `${LAMBDA_PREFIX}-contact`,
        Payload: JSON.stringify({
            name,
            text,
        })
    };
    // @ts-ignore
    return lambdaClient.send(new InvokeCommand(params))
}

const sendDownload = (name: string, email: string, city: string, school: string) => {
    const params = {
        FunctionName: `${LAMBDA_PREFIX}-download`,
        Payload: JSON.stringify({
            name,
            send_to: email,
            city,
            school,
        })
    };
    // @ts-ignore
    return lambdaClient.send(new InvokeCommand(params))
}

export {sendContact, sendDownload}
