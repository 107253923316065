import React from 'react';
import {PageId} from "./paging/Page";

type Props = {
    start: PageId;
    onChange: (page: PageId) => void;
};

const Intro = ({start, onChange}: Props) => {
    return <div>
        <p>
            Ślad ekologiczny jest naukową koncepcją umożliwiającą oszacowanie zużycia zasobów naturalnych
            w stosunku do możliwości ich odtworzenia przez Ziemię. Kalkulator śladu ekologicznego pozwala
            w przybliżony sposób oszacować Twój wpływ na ziemski ekosystem.
        </p>
        <p>
            Wybierz stwierdzenia, które są najbliższe Twojemu stylowi życia. &nbsp;
            <strong>W danym obszarze możesz wybrać więcej niż jedno stwierdzenie.</strong>
        </p>
        <p className="text-center">
            <button type="button"
                    className={`btn btn-primary fs-5`}
                    onClick={() => onChange(start)}>
                Rozpocznij
            </button>
        </p>
    </div>;
};

export default Intro;
