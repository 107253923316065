import React from 'react';
import {Question, QuestionSection} from './model';
import Pages from './ui/paging/Pages';
import {PageId} from "./ui/paging/Page";
import Summary from "./ui/Summary";
import QuestionSectionDiv from "./ui/QuestionSectionDiv";
import QuestionNav from "./ui/QuestionNav";
import Intro from "./ui/Intro";

type Props = {
    sections: QuestionSection[];
}

type State = {
    points: Map<Question, number>;
    page: PageId;
}

export class QuestionForm extends React.Component<Props, State> {
    private readonly introPageId = "O kalkulatorze";

    constructor(props: Props) {
        super(props);
        this.state = {
            points: new Map<Question, number>(),
            page: this.introPageId
        };
    }

    render() {
        const intro = this.intro();
        const summary = this.summary();
        const pageIds = [intro.title].concat(this.props.sections.map(s => s.title)).concat(summary.title);
        const pages = [intro].concat(this.props.sections.map((question, i) => {
            return this.toPage(question, i, pageIds);
        })).concat(summary);
        return <Pages selectedPage={this.state.page}
                      pages={pages}
                      onChange={page => this.changePage(page)}/>;
    }

    private toPage(section: QuestionSection, i: number, pages: PageId[]) {
        const id = `question-${i}`;
        return {
            title: section.title,
            children: <div>
                <QuestionSectionDiv key={id}
                                    id={id}
                                    section={section}
                                    onChange={(question, points, clear) => this.onChange(question, points, clear)}/>
                <QuestionNav selectedPage={this.state.page}
                             pages={pages}
                             points={this.calculatePoints()}
                             onChange={page => this.changePage(page)}/>
            </div>
        }
    }

    private intro() {
        return {
            title: this.introPageId,
            children: <Intro start={this.props.sections[0].title}
                             onChange={page => this.changePage(page)}/>,
        };
    }

    private summary() {
        return {
            title: "Podsumowanie",
            children: <Summary points={this.calculatePoints()}/>,
        };
    }

    private onChange = (question: Question, points: number, clear: Question[]) => {
        const copy = new Map(this.state.points);
        copy.set(question, points);
        clear.forEach(q => copy.set(q, 0));
        this.setState({points: copy});
    };

    private calculatePoints() {
        return Array.from(this.state.points.values())
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }

    private changePage(page: PageId) {
        const section = this.props.sections.find(section => section.title === page);
        const points = new Map(this.state.points);
        if (section !== undefined) {
            section.questions.forEach(question => {
                if (!points.has(question)) {
                    points.set(question, question.initial || 0);
                }
            });
        }
        this.setState({page, points});
    }
}
