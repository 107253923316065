import React from 'react';
import {Answer} from '../../model';

type Props = {
    answer: Answer;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

const AnswerCheckbox = ({answer, checked, onChange}: Props) => {
    return <li className={`list-group-item ${checked ? "bg-primary bg-opacity-25" : ""}`}>
        <label className="form-check form-check-label">
            <input className="form-check-input"
                   type="checkbox"
                   checked={checked}
                   onChange={(e: React.FormEvent<HTMLInputElement>) => onChange(e.currentTarget.checked)}/>
            {answer.title}
        </label>
    </li>;
};

export default AnswerCheckbox;
